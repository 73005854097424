













































import Vue from 'vue';
import AccountApi from '@/apis/AccountApi';
import Auth from '@/libs/auth';

export default Vue.extend({
  data() {
    return {
      account: '',
      hasAccount: true,
      password: '',
      hasPassword: true,
      loginMessage: '',
    };
  },
  mounted() {
    // auto login
    if (Auth.loginCheck()) {
      // redirect to Workspace and workspace (Manager.vue exactly) will redirect user to proper location
      this.$router.push({
        name: 'Workspace',
      });
    }
  },
  methods: {
    reset() {
      this.account = '';
      this.password = '';
    },
    loginButton() {
      if (this.account !== '' && this.password !== '') {
        this.hasPassword = true;
        this.hasAccount = true;
        this.handleLogin();
      } else {
        if (this.account === '') {
          this.hasAccount = false;
        } else {
          this.hasAccount = true;
        }
        if (this.password === '') {
          this.hasPassword = false;
        } else {
          this.hasPassword = true;
        }
      }
    },
    async handleLogin() {
      try {
        const { profile, auth } = await AccountApi.login(this.account, this.password);
        this.$cookies.set('token', auth.token);
        this.$store.commit('saveProfile', profile);
        this.$router.push({ name: 'Workspace' });
      } catch (err) {
        if (err && err.response && err.response.status === 401) {
          this.loginMessage = this.$t('登入失敗，錯誤的帳號或密碼').toString();
          this.reset();
          setTimeout(() => {
            this.loginMessage = '';
          }, 3000);
        } else {
          console.error(err);
        }
      }
    },
  },
});
